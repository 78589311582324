import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';

import { Input, Table, Select, Tag, Tooltip, Form, Switch, notification, InputNumber } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, EyeFilled, ReloadOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception } from '@extensions/notification';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { getEnumList, delayAction } from '@extensions/utils';

import { serverFetch } from '@src/core/server';
import { IConsigneeFilter } from '@entities/consignee-filter';
import { IConsignee } from '@entities/consignee';
import { ICity } from '@entities/city';
import { IUser } from '@entities/user';

import { ConsigneeType, enumLabel as consigneeEnumLabel } from '@enums/consignee-type';
import { DeliveryType, enumLabel as deliveryTypeLabel } from '@enums/delivery-type';
import { UserType } from '@enums/user-type';

import { UsersIcon, UserIcon, WarehouseIcon, PickupIcon } from '@src/core/icons';

const filterContext: string = 'Consignees';

const Consignees = () => {
    const initFilter: IConsigneeFilter = { isArchived: false, isLockedOnly: false };

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();

    const fileInputRef = useRef(null);

    const d = useAppDispatch();
    const filter = useAppSelector<IConsigneeFilter>((s) => s.filters[filterContext]);

    const [entities, setEntities] = useState<Array<IConsignee>>([]);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [contractors, setContractors] = useState<Array<IUser>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentEntity, setCurrentEntity] = useState<IConsignee>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [consigneeTypes] = useState(getEnumList(ConsigneeType, consigneeEnumLabel));

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            const promises = [
                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('users', { method: 'GET', queryParams: { types: [UserType.Supplier] /* isActiveOnly: true  */ } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения поставщиков', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0]);
                setContractors(result[0][1]);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        refresh();
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const refresh = () => {
        setEntities([]);

        setLoading(true);

        serverFetch('consignees', { method: 'GET', queryParams: filter })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения грузополучателей', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            refresh();
                        },
                    },
                    {
                        label: 'Информация о грузополучателе',
                        key: 'edit',
                        disabled: !currentEntity,
                        icon: <EyeFilled />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentEntity?.id}`);
                        },
                    },
                ]}
                filter={{
                    active: showFilter,
                    onHideFilter: () => {
                        setShowFilter(!showFilter);
                    },
                }}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <InputNumber
                            key='searchText'
                            placeholder='ID клиента / ИНН / Контрагент'
                            value={filter?.searchText}
                            style={{ width: 230 }}
                            onChange={(value) => {
                                d(setFilter({ ...filter, searchText: value }, filterContext));
                            }}
                        />,
                        <Input
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            maxTagCount='responsive'
                            key='contractor'
                            placeholder='Поставщик'
                            value={filter?.contractorIds}
                            size='middle'
                            mode='multiple'
                            allowClear={true}
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, contractorIds: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={contractors.map((c) => {
                                return { value: c.id, label: c.login };
                            })}
                        />,
                        <Select
                            key='type'
                            placeholder='Тип'
                            allowClear={true}
                            style={{ width: 150 }}
                            value={filter?.type}
                            onChange={(value) => {
                                d(setFilter({ ...filter, type: value }, filterContext));
                            }}
                            options={consigneeTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />,
                        <Select
                            key='city'
                            placeholder='Город'
                            value={filter?.cityId}
                            allowClear={true}
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, cityId: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        />,
                        <Form.Item key='isLockedOnly' label='Заблокированные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.isLockedOnly}
                                onChange={(value) => {
                                    d(setFilter({ ...filter, isLockedOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = entities.find((o) => o.id === selectedRowKeys[0]);
            setCurrentEntity(entity);
        } else {
            setCurrentEntity(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IConsignee> = [
            {
                title: 'ID',
                align: 'center',
                width: 90,
                sortDirections: ['ascend', 'descend', 'ascend'],
                defaultSortOrder: 'ascend',
                sorter: (a, b) => (a.code || 0) - (b.code || 0),
                onCell: (record) => ({
                    style: {
                        background: record.isLocked ? '#ff9999' : '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record: IConsignee) => {
                    return (
                        <Tooltip placement='top' title={record.lockReason} color='red'>
                            <div>{record.code}</div>
                            <div>
                                <Tooltip placement='top' title={`Тип: "${consigneeEnumLabel(record.type)}"`}>
                                    {record.type === ConsigneeType.GroupMember ? (
                                        <UsersIcon style={{ fontSize: 18 }} />
                                    ) : (
                                        <UserIcon style={{ fontSize: 14 }} />
                                    )}
                                </Tooltip>
                                <Tooltip placement='top' title={`Доставка: "${deliveryTypeLabel(record.deliveryType)}"`}>
                                    {record.deliveryType === DeliveryType.OnHand ? (
                                        <PickupIcon style={{ fontSize: 14, marginLeft: 5 }} />
                                    ) : (
                                        <WarehouseIcon style={{ fontSize: 14, marginLeft: 5 }} />
                                    )}
                                </Tooltip>
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'allMarkings',
                width: 300,
            },
            {
                title: 'Город доставки',
                dataIndex: 'cityName',
                width: 150,
            },
            {
                title: 'Поставщик',
                width: 130,
                dataIndex: 'supplier',
            },

            {
                title: 'Тег',
                width: 150,
                render: (_, record) => {
                    return (
                        <>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                title: 'Город',
                dataIndex: 'legalCityName',
                width: 150,
            },
            {
                title: 'Адрес',
                dataIndex: 'address',
                width: 350,
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 250,
            },
            {
                title: '',
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    columnWidth: 48,
                }}
                columns={columns}
                dataSource={entities}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                virtual={true}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 182px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            <input
                type='file'
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                onChange={(x: any) => {
                    let file = x.target.files[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            if (reader && reader.result) {
                                var fileBody = (reader.result as string).replace(
                                    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
                                    ''
                                );

                                serverFetch(`consignees/import`, { method: 'POST', bodyData: fileBody })
                                    .then(() => {})
                                    .catch((ex) => {
                                        exception(api, 'Ошибкаипорта файлаа', ex, () => d(userLoaded(undefined)));
                                    });
                            }

                            reader.onerror = (error) => console.log(error);
                        };
                    }
                }}
                onClick={(event: any) => {
                    event.target.value = null;
                }}
                multiple={false}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />

            {contextHolder}
        </div>
    );
};

export default Consignees;
