import React, { useState, useEffect } from 'react';

import { Steps, Input } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import { notification } from 'antd';

import { userLoaded } from '@store/actions';

import { exception } from '@extensions/notification';
import { getDate } from '@extensions/utils';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IWaypoint } from '@entities/waypoint';
import { ITrackpoint } from '@entities/trackpoint';

import { WaypointType, enumLabel as waypointTypeLabel } from '@enums/waypoint-type';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IPoint {
    waypointid: string | undefined;
    title: string;
    description?: string;
    subTitle?: string;
}

const Trackpoints = ({ ...props }) => {
    const [api, contextHolder] = notification.useNotification();

    const { truckId } = props;

    const d = useAppDispatch();

    const [baseWaypoints, setBaseWaypoints] = useState<Array<IWaypoint>>([]);
    const [points, setPoints] = useState<Array<IPoint>>([]);
    const [trackpoints, setTrackpoints] = useState<Array<ITrackpoint>>([]);
    const [currentindex, setCurrentIndex] = useState<number>(-1);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setCurrentIndex(-1);
        setPoints([]);
        setTrackpoints([]);
        setBaseWaypoints([]);

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch(`trackpoints/${truckId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маршрутных точек', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`trucks/${truckId}/foreignwaypoints`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения путевых точек', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setTrackpoints(result[0][0]);
                setBaseWaypoints(result[0][1]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        let points: Array<IPoint> = [];

        let tmpTrackpoints = [...trackpoints];

        if (baseWaypoints.length <= 0) return;

        let waypoints: Array<IWaypoint> = [];
        let extraTrackpoints = trackpoints.filter((t) => t.isExtraPoint);

        baseWaypoints.map((w) => {
            let extraPoints = extraTrackpoints.filter((t) => t.waypointId === w.id);
            if (extraPoints.length > 0) {
                extraPoints.map((p) => {
                    waypoints.push({ ...w, type: WaypointType.Onway, extraTrackpointId: p.id });
                });
            }

            waypoints.push(w);
        });

        waypoints.map((w) => {
            let title: any = '';

            if (w.type === WaypointType.Onway) {
                title = (
                    <>
                        <CarOutlined />
                    </>
                );
            } else {
                title = (
                    <>
                        {w.name} ({waypointTypeLabel(w.type)})
                    </>
                );
            }

            let point: IPoint = { waypointid: w.id, title: title };

            let trackpoint = w.extraTrackpointId
                ? trackpoints.find((t) => t.id === w.extraTrackpointId)
                : trackpoints.find((t) => !t.isExtraPoint && t.waypointId === w.id);

            if (trackpoint) {
                point.description = trackpoint.comment;
                point.subTitle = getDate(trackpoint?.date);
            }

            points.push(point);
        });

        let lastTrackPoint: ITrackpoint | undefined = tmpTrackpoints.pop();

        if (lastTrackPoint) {
            let waypoint = lastTrackPoint.isExtraPoint
                ? waypoints.find((w) => w.extraTrackpointId === lastTrackPoint?.id)
                : waypoints.find((w) => !w.extraTrackpointId && w.id === lastTrackPoint?.waypointId);

            if (waypoint) {
                let index = waypoints.indexOf(waypoint);
                setCurrentIndex(index);
            }
        }

        setPoints(points);
    }, [baseWaypoints, trackpoints]);

    return (
        <>
            <div style={{ marginTop: 20 }}>
                {!loading && <Steps progressDot direction='vertical' current={currentindex} items={points} />}
            </div>

            {contextHolder}
        </>
    );
};

export default Trackpoints;
