import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Table, Input, Select, Modal, Tooltip, Tag, DatePicker, Flex, notification, InputNumber } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, CarOutlined, EyeFilled } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import ConsignmentCard, { IBoxGroupCardItem } from '@controls/consignment-card/consignment-card';

import { delayAction, getEnumList, getDate } from '@extensions/utils';
import { exception } from '@extensions/notification';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { userLoaded, setFilter } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { ITruckFilter } from '@entities/truck-filter';
import { ITruck } from '@entities/truck';
import { ICity } from '@entities/city';
import { IConsignment } from '@entities/consignment';
import { ICountry } from '@entities/country';

import { TruckStatus, enumLabel } from '@enums/truck-status';
import { IEnumItem } from '@enums/enum-item';

dayjs.extend(utc);

const filterContext: string = 'TransitTrucks';

const Trucks = () => {
    const initFilter: ITruckFilter = {
        statuses: [TruckStatus.New, TruckStatus.OnWay],
        isArchived: false,
    };

    const { RangePicker } = DatePicker;

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const [api, notificationContextHolder] = notification.useNotification();
    const [modal, contextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const filter = useAppSelector<ITruckFilter>((s) => s.filters[filterContext]);

    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [currentTruck, setCurrentTruck] = useState<ITruck>();
    const [countries, setCountries] = useState<Array<ICountry>>([]);

    const [statuses] = useState<Array<IEnumItem>>(getEnumList(TruckStatus, enumLabel));

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('transitWarehouse/trucks', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);
                setTrucks(result[0][1] || []);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Управление машиной',
                        key: 'add',
                        disabled: !currentTruck,
                        icon: <CarOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentTruck?.id}`);
                        },
                    },
                ]}
                filter={{
                    active: showFilter,
                    onHideFilter: () => {
                        setShowFilter(!showFilter);
                    },
                }}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                display={showFilter}
                items={[
                    <Input
                        style={{ width: 130 }}
                        key='number'
                        placeholder='Машина'
                        value={filter?.number}
                        onChange={(data) => {
                            d(setFilter({ ...filter, number: data.target.value }, filterContext));
                        }}
                    />,
                    <InputNumber
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter?.consigneeCode}
                        onChange={(value) => {
                            d(setFilter({ ...filter, consigneeCode: value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Select
                        key='status'
                        placeholder='Статус машины'
                        value={filter?.statuses}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => d(setFilter({ ...filter, statuses: value }, filterContext))}
                        options={statuses.map((s) => {
                            return { value: s.value, label: s.label };
                        })}
                    />,
                    <RangePicker
                        style={{ width: 220 }}
                        allowEmpty={[true, true]}
                        key='departure'
                        format='DD.MM.YYYY'
                        placeholder={['Отгрузка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={[
                            filter?.departureFrom ? dayjs(filter?.departureFrom) : null,
                            filter?.departureTo ? dayjs(filter?.departureTo) : null,
                        ]}
                        onChange={(value) => {
                            if (!value) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            departureFrom: undefined,
                                            departureTo: undefined,
                                        },
                                        filterContext
                                    )
                                );

                                return;
                            }

                            d(
                                setFilter(
                                    {
                                        ...filter,
                                        departureFrom: value[0]
                                            ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                        departureTo: value[1]
                                            ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                    },
                                    filterContext
                                )
                            );
                        }}
                    />,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            const entity = trucks.find((o: ITruck) => o.journalId === selectedRowKeys[0]);
            setCurrentTruck(entity);
        } else {
            setCurrentTruck(undefined);
        }
    };

    const expandedConsignments = (record: ICity) => {
        const consignments = record.consignments?.map((p) => {
            const boxGroups: any = [];
            let totalQty: number = 0;

            p.boxGroups?.map((b) => {
                if (!b.countryId) return;

                boxGroups[b.countryId] = { qty: b.qty, awbNumber: b.awbNumber };
                totalQty += b.qty || 0;
            });

            p = {
                ...p,
                ...boxGroups,
                totalQty: totalQty,
            };
            return p;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                width: 80,
                align: 'center',
            },
            {
                title: 'Маркировка',
                width: 200,
                dataIndex: 'markingCode',
            },
            {
                dataIndex: 'countries',
                width: 375,
                render: (_, record) => {
                    return (
                        <Flex gap='small'>
                            {countries.map((c) => {
                                const boxGroups = record.boxGroups?.filter((bg) => bg.countryId === c.id);
                                if (!boxGroups || boxGroups.length <= 0) return <></>;

                                return <ConsignmentCard country={c} api={api} items={boxGroups as Array<IBoxGroupCardItem>} />;
                            })}
                        </Flex>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {},
        ];

        return <Table rowKey='id' size='small' columns={columns} dataSource={consignments} pagination={false} />;
    };

    const expandedCities = (record: ITruck) => {
        const cities: Array<ICity> = [];
        const cityNames = [];

        record.consignments?.map((e) => {
            let city = cities.find((c) => c.name === e.cityName && c.tag === e.tag);
            if (!city) {
                city = {
                    cityKey: `${e.cityId}#${e.tag}`,
                    name: e.cityName,
                    tag: e.tag,
                    totalQty: 0,
                    consignments: [],
                };

                cities.push(city);
                cityNames.push(e.cityName);
            }

            e.boxGroups?.map((b) => {
                if (city) {
                    if (!city.totalQty) city.totalQty = 0;

                    city.totalQty += b.qty || 0;
                }
            });

            city.consignments?.push(e);
        });

        const columns: ColumnsType<ICity> = [
            {
                title: 'Город доставки',
                width: 653,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.name}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
            },
            {},
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                columns={columns}
                dataSource={cities}
                pagination={false}
                expandable={{
                    columnWidth: 32,
                    expandedRowRender: expandedConsignments /* , defaultExpandedRowKeys: cityNames  */,
                }}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ITruck> = [
            /*     {
                title: 'Журнал',
                dataIndex: 'journalNumber',
                width: 100,
            }, */
            {
                title: 'Машина',
                dataIndex: 'number',
                width: 150,
            },
            {
                title: 'Статус',
                width: 100,
                render: (_, record) => {
                    return (
                        <>
                            {record.status ? enumLabel(record.status) : 'Не назначена'}
                            {record.trackingUrl && (
                                <Tooltip placement='topRight' title='Показать на карте'>
                                    <a className='trackingUrl' href={record.trackingUrl} target='_blank' rel='noreferrer'>
                                        <EyeFilled />
                                    </a>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Отгрузка',
                align: 'center',
                width: 100,
                render: (_, record) => {
                    return getDate(record.departureOn);
                },
            },
            {
                title: 'Телефон водителя',
                dataIndex: 'driverPhone',
                width: 150,
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
            },

            {},
        ];

        return (
            <Table
                rowKey='journalId'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={trucks}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? 240 : 182}px)`, x: 'max-content' }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                onRow={(record: ITruck) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                expandable={{
                    columnWidth: 32,
                    expandedRowRender: expandedCities,
                }}
            />
        );
    };

    return (
        <>
            {contextHolder}
            {notificationContextHolder}
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}
        </>
    );
};

export default Trucks;
