import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Input, Modal, Table, Select, DatePicker, Tooltip, Flex, Tag, notification, InputNumber } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, CarOutlined, PrinterOutlined, EyeFilled } from '@ant-design/icons';

import printJS from 'print-js';

import Comment from '@controls/comment/comment';
import PrintProcessing from '@controls/print-processing';
import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import ConsignmentCard, { IBoxGroupCardItem } from '@controls/consignment-card/consignment-card';

import { exception } from '@extensions/notification';
import { getEnumList, delayAction, getDate, dataURItoBlob } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { ITruckFilter } from '@entities/truck-filter';
import { ITruck } from '@entities/truck';
import { IConsignment } from '@entities/consignment';
import { ICountry } from '@entities/country';
import { ICity } from '@entities/city';

import { TruckType } from '@enums/truck-type';
import { TruckStatus, enumLabel as truckStatusLabel } from '@enums/truck-status';
import { IEnumItem } from '@enums/enum-item';

dayjs.extend(utc);

const filterContext: string = 'ImportTrucks';

const Trucks = () => {
    const initFilter: ITruckFilter = {
        type: TruckType.Import,
        statuses: [TruckStatus.New, TruckStatus.OnWay],
        isArchived: false,
    };

    const { RangePicker } = DatePicker;

    const [modal, contextHolder] = Modal.useModal();
    const [api, notificationContextHolder] = notification.useNotification();

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const d = useAppDispatch();
    const filter = useAppSelector<ITruckFilter>((s) => s.filters[filterContext]);

    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [currentTruck, setCurrentTruck] = useState<ITruck>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [selectedConsignments, setSelectedConsignments] = useState<Array<IConsignment>>([]);
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [statuses] = useState<Array<IEnumItem>>(getEnumList(TruckStatus, truckStatusLabel));

    const [startPrint, setStartPrint] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setCountries([]);
        setTrucks([]);

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('trucks/import', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);
                setTrucks(result[0][1] || []);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Информация о машине',
                        key: 'edit',
                        disabled: !currentTruck,
                        icon: <CarOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentTruck?.id}`);
                        },
                    },
                    {
                        label: 'Печать',
                        key: 'print',
                        disabled: loading || !currentTruck || startPrint,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            setStartPrint(true);

                            serverFetch(`trucks/print`, { method: 'POST', bodyData: [currentTruck?.id] })
                                .then((data) => {
                                    const fileBody = `data:${data.contentType};base64,${data.fileContents}`;

                                    const blobURL = URL.createObjectURL(dataURItoBlob(fileBody));
                                    printJS(blobURL);

                                    setStartPrint(false);
                                })
                                .catch((ex) => {
                                    setStartPrint(false);
                                    exception(api, 'Ошибка выгрузки листа приемки', ex, () => d(userLoaded(undefined)));
                                });
                        },
                    },
                ]}
                filter={{
                    active: showFilter,
                    onHideFilter: () => {
                        setShowFilter(!showFilter);
                    },
                }}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                display={showFilter}
                items={[
                    <Input
                        style={{ width: 130 }}
                        key='number'
                        placeholder='Машина'
                        value={filter?.number}
                        onChange={(data) => {
                            d(setFilter({ ...filter, number: data.target.value }, filterContext));
                        }}
                    />,
                    <InputNumber
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter?.consigneeCode}
                        onChange={(value) => {
                            d(setFilter({ ...filter, consigneeCode: value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Select
                        key='status'
                        placeholder='Статус машины'
                        value={filter?.statuses}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => d(setFilter({ ...filter, statuses: value }, filterContext))}
                        options={statuses.map((s) => {
                            return { value: s.value, label: s.label };
                        })}
                    />,
                    <RangePicker
                        style={{ width: 220 }}
                        allowEmpty={[true, true]}
                        key='departure'
                        format='DD.MM.YYYY'
                        placeholder={['Загрузка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={[
                            filter?.departureFrom ? dayjs(filter?.departureFrom) : null,
                            filter?.departureTo ? dayjs(filter?.departureTo) : null,
                        ]}
                        onChange={(value) => {
                            if (!value) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            departureFrom: undefined,
                                            departureTo: undefined,
                                        },
                                        filterContext
                                    )
                                );

                                return;
                            }

                            d(
                                setFilter(
                                    {
                                        ...filter,
                                        departureFrom: value[0]
                                            ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                        departureTo: value[1]
                                            ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                    },
                                    filterContext
                                )
                            );
                        }}
                    />,
                    <RangePicker
                        style={{ width: 220 }}
                        allowEmpty={[true, true]}
                        key='finishedOn'
                        format='DD.MM.YYYY'
                        placeholder={['Приемка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={[
                            filter?.finishedFrom ? dayjs(filter?.finishedFrom) : null,
                            filter?.finishedTo ? dayjs(filter?.finishedTo) : null,
                        ]}
                        onChange={(value) => {
                            if (!value) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            finishedFrom: undefined,
                                            finishedTo: undefined,
                                        },
                                        filterContext
                                    )
                                );

                                return;
                            }

                            d(
                                setFilter(
                                    {
                                        ...filter,
                                        finishedFrom: value[0]
                                            ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                        finishedTo: value[1]
                                            ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                    },
                                    filterContext
                                )
                            );
                        }}
                    />,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            const item = trucks.find((o) => o.id === selectedRowKeys[0]);
            setCurrentTruck(item);
        } else {
            setCurrentTruck(undefined);
        }

        const items: Array<IConsignment> = [];
        selectedRowKeys.map((e) => {
            const item = trucks.find((o) => o.id === e);
            if (item && item.consignments) items.push(...item.consignments);
        });

        setSelectedConsignments(items);
    };

    const expandedConsignments = (record: ICity) => {
        const consignments = record.consignments?.map((p) => {
            const boxGroups: any = [];
            let totalQty: number = 0;
            let totalVolume: number = 0;

            p.boxGroups?.map((b) => {
                if (!b.countryId) return;

                boxGroups[b.countryId] = b.qty;
                totalQty += b.qty || 0;
                totalVolume += b.calcVolume || 0;
            });

            p = {
                ...p,
                ...boxGroups,
                totalQty: totalQty,
                totalVolume: totalVolume,
            };
            return p;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                width: 60,
                align: 'center',
                render: (_: any, record: IConsignment) => {
                    return <>{record.consigneeCode}</>;
                },
            },
            {
                title: 'Маркировка',
                width: 200,
                dataIndex: 'markingCode',
            },
            {
                dataIndex: 'countries',
                width: 375,
                render: (_, record) => {
                    return (
                        <Flex gap='small'>
                            {countries.map((c) => {
                                const boxGroups = record.boxGroups?.filter((bg) => bg.countryId === c.id);
                                if (!boxGroups || boxGroups.length <= 0) return <></>;

                                return <ConsignmentCard country={c} api={api} items={boxGroups as Array<IBoxGroupCardItem>} />;
                            })}
                        </Flex>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                dataIndex: 'totalVolume',
                width: 110,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return record.totalVolume?.toFixed(2);
                },
            },
            {},
        ];

        return <Table rowKey='id' size='small' columns={columns} dataSource={consignments} pagination={false} />;
    };

    const expandedCities = (record: ITruck) => {
        const cities: Array<ICity> = [];

        record.consignments?.map((e) => {
            let city = cities.find((c) => c.name === e.cityName && c.tag === e.tag);
            if (!city) {
                city = {
                    cityKey: `${e.cityName}#${e.tag}`,
                    name: e.cityName,
                    tag: e.tag,
                    totalQty: 0,
                    totalVolume: 0,
                    consignments: [],
                };

                cities.push(city);
            }

            e.boxGroups?.map((b) => {
                if (city) {
                    if (!city.totalQty) city.totalQty = 0;
                    if (!city.totalVolume) city.totalVolume = 0;

                    city.totalQty += b.qty || 0;
                    city.totalVolume += b.calcVolume || 0;
                }
            });

            city.consignments?.push(e);
        });

        const columns: ColumnsType<ICity> = [
            {
                title: 'Город доставки',
                width: 633,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.name}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                width: 110,
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return record.totalVolume?.toFixed(2);
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                columns={columns}
                dataSource={cities}
                pagination={false}
                expandable={{
                    columnWidth: 32,
                    expandedRowRender: expandedConsignments /* , defaultExpandedRowKeys: cityNames  */,
                }}
            />
        );
    };

    const renderTable = () => {
        const lines = trucks.map((t) => {
            let totalQty = 0;
            let totalVolume = 0;

            t.consignments?.map((p) => {
                p.boxGroups?.map((b) => {
                    totalQty += b.qty || 0;
                    totalVolume += b.calcVolume || 0;
                });
            });

            t = {
                ...t,
                totalQty: totalQty,
                totalVolume: totalVolume,
            };
            return t;
        });

        const columns: ColumnsType<ITruck> = [
            {
                title: 'Машина',
                dataIndex: 'number',
                key: 'number',
                width: 150,
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (_, record) => {
                    return (
                        <>
                            {record.status ? truckStatusLabel(record.status) : 'Не назначена'}
                            {record.trackingUrl && (
                                <Tooltip placement='topRight' title='Показать на карте'>
                                    <a className='trackingUrl' href={record.trackingUrl} target='_blank' rel='noreferrer'>
                                        <EyeFilled />
                                    </a>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Отгрузка',
                width: 100,
                render: (_, record) => {
                    return getDate(record.departureOn);
                },
            },
            {
                width: 40,
                align: 'center',
                render: (_, record) => {
                    return record.comment && <Comment comment={record.comment} />;
                },
            },
            {
                title: 'Кол-во / шт',
                dataIndex: 'totalQty',
                key: 'totalQty',
                width: 120,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: (
                    <>
                        Общий объем / м<sup>3</sup>
                    </>
                ),
                width: 160,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return record.totalVolume?.toFixed(2);
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{
                    columnWidth: 32,
                    expandedRowRender: expandedCities,
                }}
                dataSource={lines}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? '240px' : '175px'})`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {startPrint && <PrintProcessing />}

            {contextHolder}
            {notificationContextHolder}
        </>
    );
};

export default Trucks;
