import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { notification } from 'antd';

import { Input, InputNumber, Form, Select, AutoComplete, Tooltip } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

import PhoneInputFormItem from '@controls/phone-input-form-item/phone-input-form-item';
import FormHeader from '@controls/form-header/form-header';

import { Permission, hasPermission } from '@enums/permission';
import { getEnumList } from '@extensions/utils';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IConsignee } from '@entities/consignee';
import { ICity } from '@entities/city';
import { IUserFilter } from '@entities/user-filter';
import { IUser } from '@entities/user';
import { ITag } from '@entities/tag';

import { UserType } from '@enums/user-type';
import { ConsigneeType, enumLabel as consigneeEnumLabel } from '@enums/consignee-type';
import { DeliveryType, enumLabel as deliveryEnumLabel } from '@enums/delivery-type';

const Consignee = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const { TextArea } = Input;

    const [form] = Form.useForm();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<IConsignee>();

    const [consigneeTypes] = useState(getEnumList(ConsigneeType, consigneeEnumLabel));
    const [deliveryTypes] = useState(getEnumList(DeliveryType, deliveryEnumLabel));
    const [loading, setLoading] = useState<boolean>(false);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [tags, setTags] = useState<Array<ITag>>([]);
    const [users, setUsers] = useState<Array<IUser>>([]);

    const [isViewOnly] = useState<boolean>(true);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let userFilter: IUserFilter = { isActive: true, isArchived: false, types: [UserType.Supplier] };

            const promises = [
                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('users', { method: 'GET', queryParams: userFilter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('tags', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения тегов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`consignees/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения грузополучателя', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0]);
                setUsers(result[0][1]);
                setTags(result[0][2]);

                let consignee = result[0][3];

                setEntity(consignee || { id: undefined, type: ConsigneeType.Main, deliveryType: DeliveryType.OnHand });

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    const onSave = () => {
        setLoading(true);

        serverFetch(`consignees`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения грузополучателя', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader
                title={`${id ? (isViewOnly ? 'Информация о грузополучателе' : 'Изменить грузополучателя') : 'Добавить грузополучателя'}`}
            />
            {entity && (
                <Form preserve={false} colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} form={form} onFinish={onSave}>
                    <Form.Item
                        initialValue={entity?.code}
                        required
                        validateFirst={true}
                        label='ID'
                        name='code'
                        rules={[
                            { required: true, message: 'Укажите ID' },
                            {
                                validator: async (_, value) => {
                                    let isCodeExist = await serverFetch(`consignees/codeexist/${id}/${value}`, { method: 'GET' })
                                        .then((data) => {
                                            return data;
                                        })
                                        .catch((ex) => {
                                            exception(api, 'Ошибка проверки кода', ex, () => d(userLoaded(undefined)));
                                        });

                                    if (isCodeExist) return Promise.reject('Грузополучатель с указанным кодом уже существует');
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            autoFocus
                            disabled={isViewOnly || (!!id && !hasPermission(userSession.permissions, Permission.FullAccess))}
                            onChange={(value) => {
                                setEntity({ ...entity, code: value as number });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={entity?.type}
                        required
                        label='Тип'
                        name='type'
                        rules={[{ required: true, message: 'Укажите тип' }]}
                    >
                        <Select
                            disabled={isViewOnly}
                            onChange={(value) => {
                                setEntity({ ...entity, type: value, userId: undefined });
                            }}
                            options={consigneeTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />
                    </Form.Item>
                    {entity.type === ConsigneeType.GroupMember && (
                        <>
                            <Form.Item
                                initialValue={entity?.userId}
                                required
                                label='Поставщик'
                                name='userId'
                                rules={[{ required: true, message: 'Укажите учетную запись основного поставщика' }]}
                            >
                                <Select
                                    onChange={(value) => {
                                        setEntity({ ...entity, userId: value });
                                    }}
                                    options={users.map((u) => {
                                        return { value: u.id, label: u.login };
                                    })}
                                />
                            </Form.Item>

                            <Form.Item
                                initialValue={entity?.deliveryType}
                                required
                                label='Тип доставки'
                                name='deliveryType'
                                rules={[{ required: true, message: 'Укажите тип доставки' }]}
                            >
                                <Select
                                    onChange={(value) => {
                                        setEntity({ ...entity, deliveryType: value });
                                    }}
                                    options={deliveryTypes.map((t) => {
                                        return { value: t.value, label: t.label };
                                    })}
                                />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item
                        initialValue={entity?.allMarkings}
                        required
                        label={
                            <>
                                Маркировка
                                <Tooltip title="Для разделения маркировок используйте разделитель '/'">
                                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </>
                        }
                        name='allMarkings'
                        rules={[{ required: true, message: 'Укажите маркировку' }]}
                    >
                        <TextArea
                            disabled={isViewOnly}
                            rows={3}
                            onChange={(data) => {
                                setEntity({ ...entity, allMarkings: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.cityId}
                        required
                        label='Город доставки'
                        name='city'
                        rules={[{ required: true, message: 'Выберите город' }]}
                    >
                        <Select
                            disabled={isViewOnly}
                            showSearch
                            onChange={(value) => setEntity({ ...entity, cityId: value })}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.tag} label='Тег' name='tag'>
                        <AutoComplete
                            allowClear
                            disabled={isViewOnly}
                            showSearch
                            onChange={(value) => setEntity({ ...entity, tag: value })}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={tags.map((c) => {
                                return { value: c.name, label: c.name };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.cityId}
                        required
                        label='Город'
                        name='city'
                        rules={[{ required: true, message: 'Выберите город' }]}
                    >
                        <Select
                            disabled={isViewOnly}
                            showSearch
                            onChange={(value) => setEntity({ ...entity, cityId: value })}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.address}
                        required
                        label='Адрес'
                        name='address'
                        rules={[{ required: true, message: 'Укажите адрес' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            onChange={(data) => {
                                setEntity({ ...entity, address: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.fullName}
                        required
                        label='ФИО'
                        name='fullName'
                        rules={[{ required: true, message: 'Укажите ФИО' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            onChange={(data) => {
                                setEntity({ ...entity, fullName: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <PhoneInputFormItem
                        value={entity?.phone}
                        name='phone'
                        label='Телефон'
                        required={true}
                        disabled={isViewOnly}
                        onChange={(value) => {
                            setEntity({ ...entity, phone: value });
                        }}
                    />
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Consignee;
