import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Auth from '@components/auth/auth';
import ImportTrucks from '@components/logistics/import/trucks';
import ImportTruck from '@components/logistics/import/truck';
import TransitTrucks from '@components/logistics/transit/trucks';
import TransitTruck from '@components/logistics/transit/truck';
import DeliveryTrucks from '@components/logistics/delivery/trucks';
import DeliveryTruck from '@components/logistics/delivery/truck';
import Remainings from '@components/warehouse/remainings/remainings';
import Remaining from '@components/warehouse/remainings/remaining';
import Performers from '@components/warehouse/performer/performers';
import Consignees from '@components/consignees/consignees';
import Consignee from '@components/consignees/consignee';
import Users from '@components/users/users';
import User from '@components/users/user';
import UserAccount from '@components/user-account/user-account';

import Acceptance from '@controls/acceptance/acceptance';
import WarehouseTruck from '@controls/acceptance/warehouse-truck';
import WarehouseConsignment from '@controls/acceptance/warehouse-consignment';
import ShipmentJournals from '@controls/shipment-journals/shipment-journals';
import ShipmentJournal from '@controls/shipment-journals/shipment-journal';
import RouteSheet from '@controls/route-sheet/route-sheet';
import ManageRoutes from '@controls/route-sheet/manage-routes';

import { useAppSelector } from '@store/hooks';

import MainLayout from './main-layout';

import '@src/core/index.css';
import { IUserSession } from '@src/core/entities/user-session';

declare global {
    interface Window {
        accessToken: string;
    }
}

const App = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    return userSession ? (
        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route index element={<Navigate replace to={`/logistics/transit`} />} />

                <Route path='logistics/import' element={<ImportTrucks />} />
                <Route path='logistics/import/:id' element={<ImportTruck />} />

                <Route path='logistics/transit' element={<TransitTrucks />} />
                <Route path='logistics/transit/:id' element={<TransitTruck />} />

                <Route path='logistics/delivery' element={<DeliveryTrucks />} />
                <Route path='logistics/delivery/new/:journalId' element={<DeliveryTruck />} />
                <Route path='logistics/delivery/:truckId/:journalId' element={<DeliveryTruck />} />

                <Route path='warehouse/acceptance' element={<Acceptance />} />
                <Route path='warehouse/acceptance/:id' element={<WarehouseTruck />} />
                <Route path='warehouse/acceptance/consignments/:consignmentId' element={<WarehouseConsignment />} />
                <Route path='warehouse/acceptance/consignments/:truckId/:markingCode' element={<WarehouseConsignment />} />
                <Route path='warehouse/remainings' element={<Remainings />} />
                <Route path='warehouse/remainings/:boxGroupId' element={<Remaining />} />
                <Route path='warehouse/remainings/shipment/:journalId?' element={<Remainings />} />
                <Route path='warehouse/shipmentjournals' element={<ShipmentJournals />} />
                <Route path='warehouse/shipmentjournals/:id' element={<ShipmentJournal />} />
                <Route path='warehouse/shipmentjournals/:journalId/truck/new' element={<DeliveryTruck />} />
                <Route path='warehouse/shipmentjournals/:journalId/truck/:truckId' element={<DeliveryTruck />} />
                <Route path='warehouse/shipmentjournals/manageoutes/:journalId/:journalNumber' element={<ManageRoutes />} />

                <Route path='warehouse/performers/:areaType/:truckId?' element={<Performers />} />
                <Route path='warehouse/routesheet/:journalId' element={<RouteSheet />} />

                <Route path='settings/consignees' element={<Consignees />} />
                <Route path='settings/consignees/:id' element={<Consignee />} />

                <Route path='settings/users' element={<Users />} />
                <Route path='settings/users/new' element={<User />} />
                <Route path='settings/users/:id' element={<User />} />

                <Route path='useraccount' element={<UserAccount />} />
            </Route>
        </Routes>
    ) : (
        <Auth />
    );
};

export default App;
